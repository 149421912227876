<ng-container [ngSwitch]="resource?.status">
  <ng-container *ngSwitchCase="'usable'">
    <mat-icon class="app-icon-xs" *ngIf="resource.type === resourceType.videos">videocam</mat-icon>
    <mat-icon class="app-icon-xs" *ngIf="resource.type === resourceType.images">image</mat-icon>
  </ng-container>
  <mat-icon class="app-icon-xs" [matTooltipPosition]="'right'" matTooltip="Analyzing metadata…"
    *ngSwitchCase="'analyzing'">info</mat-icon>
  <mat-icon class="app-icon-xs" [matTooltipPosition]="'right'" matTooltip="Extracting frames…"
    *ngSwitchCase="'framing'">content_cut</mat-icon>
  <mat-icon class="app-icon-xs app-rotate-slow" [matTooltipPosition]="'right'" matTooltip="Transconding…"
    *ngSwitchCase="'transcoding'">settings</mat-icon>
  <mat-icon class="app-icon-xs" [matTooltipPosition]="'right'" matTooltip="Resizing…"
    *ngSwitchCase="'resizing'">crop</mat-icon>
  <mat-icon class="app-icon-xs" [matTooltipPosition]="'right'" matTooltip="{{ resource.error }}"
    *ngSwitchCase="'error'">warning</mat-icon>
</ng-container>