import { Injectable } from '@angular/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { AuthenticationService } from 'app/core/auth/services/authentication.service';
import { CurrentUserService } from 'app/core/auth/services/current-user.service';
import { PaginatedResponse, PaginatedResponseInterface } from 'app/models/pagination.model';
import { SystemInterface } from 'app/models/system.model';
import { HISTORY_MAX } from 'app/modules/global/constants';
import { LS_HISTORY } from 'app/modules/global/localstorage';
import { CircuitRepositoryService } from 'app/repositories/circuit-repository.service';
import { CustomerRepositoryService } from 'app/repositories/customer-repository.service';
import { SystemRepositoryService } from 'app/repositories/system-repository.service';
import { UserRepositoryService } from 'app/repositories/user-repository.service';
import { I18nService } from 'app/services/i18n.service';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, forkJoin, Observable, of, zip } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { HistoryEnum } from './history.service';
import { FavoritesEnum } from './favorites.service';

export interface EntitiesPreloadMapInterface {
  [key: string | FavoritesEnum | HistoryEnum]: string[];
}

export interface EntitiesPreloadInterface {
  [key: string]: {
    [itemKey: string]: { id?: string }
  }
};

export interface EntitiesPreloadResponseInterface {
  [key: string]: PaginatedResponseInterface<{ id: string }>;
};

@Injectable({
  providedIn: 'root',
})
export class EntitiesPreloadService {

  entities: EntitiesPreloadInterface = {};

  entitiesChange: BehaviorSubject<EntitiesPreloadInterface> = new BehaviorSubject<EntitiesPreloadInterface>(null);

  constructor(
    protected _toastrService: ToastrService,
    protected _i18nService: I18nService,
    protected _systemRepositoryService: SystemRepositoryService,
    protected _circuitRepositoryService: CircuitRepositoryService,
    protected _userRepositoryService: UserRepositoryService,
    protected _customerRepositoryService: CustomerRepositoryService,
    protected _authenticationService: AuthenticationService,
    protected _currentUser: CurrentUserService,
  ) {

  }

  queue: EntitiesPreloadMapInterface = {};

  queueTimeout;

  isIterable(value) {
    return value != null && typeof value[Symbol.iterator] === "function";
  }

  addQueue(obj: EntitiesPreloadMapInterface, execDelay = 400) {
    // this.queue = { ...this.queue, ...obj };
    // console.log('queue add', obj);

    this.queue = Object.keys({ ...this.queue, ...obj }).reduce((result, key) => {
      const val1 = this.isIterable(this.queue[key]) ? this.queue[key] : [];
      const val2 = this.isIterable(obj[key]) ? obj[key] : [];
      result[key] = [...new Set([...val1, ...val2])];
      return result;
    }, {});

    // console.log('queue is', this.queue);

    clearTimeout(this.queueTimeout);
    if (execDelay) {
      this.queueTimeout = setTimeout(() => {
        // console.log('execQueue delayed by', execDelay);
        this.execQueue().subscribe();
      }, execDelay);
    }

  }

  execQueue() {
    const res = this.preloadMap(this.queue);
    res.pipe(
      map((data) => {
        this.queue = {};
        return data;
      })
    );
    return res;
  }

  preloadMap(obj: EntitiesPreloadMapInterface) {
    const callsMap = {};
    Object.keys(obj).forEach(entityKey => {
      callsMap[entityKey] = this.preloadItems(entityKey, obj[entityKey], true);
    });

    // console.log('preloadMap', callsMap);

    return forkJoin(callsMap).pipe(
      map((data: EntitiesPreloadResponseInterface) => {
        Object.keys(data).forEach(entityKey => {
          if (!this.entities[entityKey]) {
            this.entities[entityKey] = {};
          }
          data[entityKey].items.forEach(item => {
            this.entities[entityKey][item.id] = item;
          });
        });
        this.entitiesChange.next(this.entities);
        return data;
      })
    );
  }

  preloadItems(entityKey: string | FavoritesEnum | HistoryEnum, ids: string[], ignoreMapping: boolean = false) {
    let call: Observable<PaginatedResponse<any>>;

    // console.log('preloadItems', entityKey);

    switch (entityKey) {
      case FavoritesEnum.system:
      case HistoryEnum.system:
      case HistoryEnum.systemAnalytics: {
        call = this._systemRepositoryService.findAll({ ids: ids.join(',') }).pipe(first());
        break;
      }
      case FavoritesEnum.circuit:
      case HistoryEnum.circuit: {
        call = this._circuitRepositoryService.findAll({ ids: ids.join(',') }).pipe(first());
        break;
      }
      case FavoritesEnum.user:
      case HistoryEnum.user: {
        call = this._userRepositoryService.findAll({ ids: ids.join(',') }).pipe(first());
        break;
      }
      case FavoritesEnum.customer:
      case HistoryEnum.customer: {
        call = this._customerRepositoryService.findAll({ ids: ids.join(',') }).pipe(first());
        break;
      }
    }

    if (call && !ignoreMapping) {
      call.pipe(
        map((response: PaginatedResponseInterface<{ id: string }>) => {
          if (!this.entities[entityKey]) {
            this.entities[entityKey] = {};
          }
          response.items.forEach(item => {
            this.entities[entityKey][item.id] = item;
          });
          this.entitiesChange.next(this.entities);
        }),
      )
    }

    return call;
  }

}
