import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { BrandInterface } from 'app/models/brand.model';

export enum ResourceConstraintEnum {
  webm = 'webm',
  mp4 = 'mp4',
}

export const ResourceConstraintList = [{
  value: ResourceConstraintEnum.webm,
  label: _('webm'),
}, {
  value: ResourceConstraintEnum.mp4,
  label: _('mp4'),
}];

export const ResourceConstraintsMap = {};
ResourceConstraintsMap[ResourceConstraintEnum.webm] = {
  // codec: ['vp9', 'vp8'],
  codec: [['vp9'], ['vp8']],
  container: ResourceConstraintEnum.webm,
};
ResourceConstraintsMap[ResourceConstraintEnum.mp4] = {
  codec: [['h264']],
  container: ResourceConstraintEnum.mp4,
};

export interface ResourceConstraintInterface {
  container: string;
  codec: string[];
}

export class ResourceConstraint implements ResourceConstraintInterface {
  container: string = ResourceConstraintEnum.webm;
  codec: string[] = ResourceConstraintsMap[ResourceConstraintEnum.webm].codec[0];

  constructor(private _config: ResourceConstraintInterface) {
    Object.assign(this, this._config);
    // FIX: ignore other codecs, force first
    if (this.codec.length > 1) {
      this.codec.length = 1;
    }
    delete this._config;
  }
}

export interface ResourceCreateOptionsInterface {
  source: string;
  noWorkflow?: boolean;
  notPrivate?: boolean;
  orphan?: boolean;
  brand?: BrandInterface;
  folder?: string;
  constraints?: ResourceConstraintInterface;
}