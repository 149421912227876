import { Injectable } from '@angular/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { AuthenticationService } from 'app/core/auth/services/authentication.service';
import { CurrentUserService } from 'app/core/auth/services/current-user.service';
import { PaginatedResponse } from 'app/models/pagination.model';
import { FAVORITES_MAX } from 'app/modules/global/constants';
import { LS_FAVORITES } from 'app/modules/global/localstorage';
import { CircuitRepositoryService } from 'app/repositories/circuit-repository.service';
import { CustomerRepositoryService } from 'app/repositories/customer-repository.service';
import { SystemRepositoryService } from 'app/repositories/system-repository.service';
import { I18nService } from 'app/services/i18n.service';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, forkJoin, of, zip } from 'rxjs';
import { first } from 'rxjs/operators';
import { EntitiesPreloadService } from './entities-preload.service';

export interface FavoriteItem {
  id?: string;
}

export interface FavoritesMap {
  [userId: string]: {
    [favoriteType: string]: FavoriteItem[]
  }
}

export interface FavoritesLSMap {
  [userId: string]: {
    [favoriteType: string]: string[]
  }
}

export interface FavoritesArrayMap {
  [userId: string]: {
    [favoriteType: string]: string[]
  }
}

export interface FavoritesMappedMap {
  [userId: string]: {
    [favoriteType: string]: {
      [favoriteItemId: string]: number
    },
  }
}

export enum FavoritesEnum {
  system = 'system',
  circuit = 'circuit',
  user = 'user',
  customer = 'customer',
}

@Injectable({
  providedIn: 'root',
})
export class FavoritesService {

  protected lsKey = LS_FAVORITES;
  protected _arrays: FavoritesArrayMap = {};
  protected _maps: FavoritesMappedMap = {};

  protected _favorites: FavoritesMap = {};
  favoritesChange: BehaviorSubject<any> = new BehaviorSubject([]);

  loading: boolean = false;

  constructor(
    protected _toastrService: ToastrService,
    protected _i18nService: I18nService,
    protected _systemRepositoryService: SystemRepositoryService,
    protected _circuitRepositoryService: CircuitRepositoryService,
    protected _customerRepositoryService: CustomerRepositoryService,
    protected _authenticationService: AuthenticationService,
    protected _currentUser: CurrentUserService,
    protected _entitiesPreloadService: EntitiesPreloadService,
  ) {
    this._currentUser.onRefresh.subscribe((data) => {
      this._init();
    });
  }

  getFavorites(type: FavoritesEnum) {
    return !!this.userId && !!this._favorites && !!this._favorites[this.userId] && this._favorites[this.userId][type] || [];
  }

  getIds(type: FavoritesEnum) {
    return !!this._arrays && this._arrays[this.userId] && this._arrays[this.userId][type] || [];
  }

  toggle(type: FavoritesEnum, item: FavoriteItem) {
    return this.is(type, item) ? this.remove(type, item) : this.add(type, item);
  }

  get userId() {
    return this.isValidUser ? this._currentUser.getId() : 'guest';
  }

  clear(type: FavoritesEnum, disabledConfirmMessage?: boolean) {
    this._arrays[this.userId][type] = [];
    this._favorites[this.userId][type] = [];
    this._mapArrays();
    this._save();
    if (!disabledConfirmMessage) {
      this._toastrService.success('Preferiti cancellati');
    }
  }

  isFull(type: FavoritesEnum) {
    return this.getFavorites(type).length >= FAVORITES_MAX;
  }

  add(type: FavoritesEnum, item: FavoriteItem, disabledConfirmMessage?: boolean) {
    if (typeof item === 'object' && item.id) {
      // console.log('isFull', this.isFull(type));
      if (!this.isFull(type)) {
        if (!Array.isArray(this._arrays[this.userId][type])) {
          this._arrays[this.userId][type] = [];
        }
        this._arrays[this.userId][type].push(item.id);
        this._arrays[this.userId][type] = this._arrays[this.userId][type].slice(0, FAVORITES_MAX);
        this._mapArrays();
        if (!Array.isArray(this._favorites[this.userId][type])) {
          this._favorites[this.userId][type] = [];
        }
        this._favorites[this.userId][type].push(item);
        this._favorites[this.userId][type] = this._favorites[this.userId][type].slice(0, FAVORITES_MAX);
        this._save();
        if (!disabledConfirmMessage) {
          this._toastrService.success('Aggiunto ai preferiti');
        }
      } else {
        this._toastrService.error('Preferiti pieni');
      }
    } else {
      this._toastrService.error('Errore nei preferiti');
    }
  }

  remove(type: FavoritesEnum, item: FavoriteItem, disabledConfirmMessage?: boolean) {
    if (typeof item === 'object' && item.id) {
      // console.log(this._arrays);
      if (Array.isArray(this._arrays[this.userId][type])) {
        const idsIndex = this._arrays[this.userId][type].findIndex(id => id === item.id);
        if (idsIndex !== -1) {
          // console.log('index to remove', idsIndex);
          this._arrays[this.userId][type].splice(idsIndex, 1);
          this._mapArrays();
          this._save();
        }
      }
      if (Array.isArray(this._favorites[this.userId][type])) {
        const favIndex = this._favorites[this.userId][type].findIndex(v => v.id === item.id);
        if (favIndex !== -1) {
          // console.log('index to remove', favIndex);
          this._favorites[this.userId][type].splice(favIndex, 1);
        }
      }
      if (!disabledConfirmMessage) {
        this._toastrService.success('Rimosso dai preferiti');
      }
    } else {
      this._toastrService.error('Errore nei preferiti');
    }
  }

  is(type: FavoritesEnum, item: FavoriteItem) {
    return !!this._maps[this.userId] && !!this._maps[this.userId][type] && typeof this._maps[this.userId][type][item.id] !== 'undefined';
  }

  isLast(type: FavoritesEnum, item: FavoriteItem) {
    return !!this._arrays[this.userId] && Array.isArray(this._arrays[this.userId][type]) && this._arrays[this.userId][type].length && this._arrays[this.userId][type][this._arrays[this.userId][type].length - 1] === item.id;
  }

  // protected _getCallsMap() {
  //   const callsMap = {};
  //   Object.keys(this._arrays).forEach(userId => {
  //     if (userId === this.userId) { // current user

  //       const favoriteTypes = Object.keys(this._arrays[userId]);
  //       favoriteTypes.forEach(favoriteKey => {
  //         const ids = this._arrays[this.userId][favoriteKey];
  //         if (Array.isArray(ids) && ids.length) {
  //           switch (favoriteKey) {
  //             case FavoritesEnum.system: {
  //               callsMap[favoriteKey] = this._systemRepositoryService.findAll({ ids: ids.join(',') }).pipe(first());
  //               break;
  //             }
  //             case FavoritesEnum.circuit: {
  //               callsMap[favoriteKey] = this._circuitRepositoryService.findAll({ ids: ids.join(',') }).pipe(first());
  //               break;
  //             }
  //             case FavoritesEnum.customer: {
  //               callsMap[favoriteKey] = this._customerRepositoryService.findAll({ ids: ids.join(',') }).pipe(first());
  //               break;
  //             }
  //           }
  //         }
  //       });
  //     }
  //   });

  //   // console.log('_getCallsMap callsMap', callsMap)

  //   return callsMap;
  // }

  protected _mapArrays() {
    this._maps = {};
    const userIds = Object.keys(this._arrays);
    userIds.forEach(userId => {
      this._maps[userId] = {};
      const favoriteTypes = Object.keys(this._arrays[userId]);
      favoriteTypes.forEach(favoriteKey => {
        this._maps[userId][favoriteKey] = {};
        const ids = this._arrays[userId][favoriteKey];
        if (Array.isArray(ids)) {
          ids.forEach(id => {
            this._maps[userId][favoriteKey][id] = 1;
          });
        }
      });
    });
  }

  private _seed() {
    if (!this._arrays[this.userId] || typeof this._arrays[this.userId][FavoritesEnum.system] === 'undefined') {
      const oldLsKey = 'system.favorites';
      const oldFavorites = localStorage.getItem(oldLsKey) ? JSON.parse(localStorage.getItem(oldLsKey)) : [];
      if (Array.isArray(oldFavorites) && oldFavorites.length) {
        this._arrays[this.userId][FavoritesEnum.system] = oldFavorites.map(s => s.id);
        this._save();
        localStorage.removeItem(oldLsKey);
      } else {
        const newFavorites = this._arrays[FavoritesEnum.system];
        if (Array.isArray(newFavorites) && newFavorites.length) {
          this._arrays[this.userId][FavoritesEnum.system] = newFavorites;
          delete this._arrays[FavoritesEnum.system];
          this._save();
        }
      }
    }
  }

  protected _load() {
    // console.log('::: LOADING :::', this.lsKey, this);
    const currentUserObj1 = {};
    currentUserObj1[this.userId] = {};
    this._arrays = Object.assign({}, currentUserObj1, (localStorage.getItem(this.lsKey) ? JSON.parse(localStorage.getItem(this.lsKey)) : {}));

    const currentUserObj2 = {};
    currentUserObj2[this.userId] = {};
    this._favorites = Object.assign({}, currentUserObj2);

    this._seed();
    this._mapArrays();
  }

  protected _save(): void {
    localStorage.setItem(this.lsKey, JSON.stringify(this._arrays));
    this.favoritesChange.next(this._favorites[this.userId]);
  }

  protected get isValidUser() {
    return this._authenticationService.hasToken() && this._currentUser.hasUser() && this._currentUser.isEnabled();
  }

  protected _init() {
    // console.log('::: FAVORITES INIT :::', this.lsKey, this);
    if (this.isValidUser) {
      this.loading = true;
      // console.log('::: VALID USER :::', this.lsKey, this);
      this._load();

      const currentUserFavoritesMap = this._arrays[this.userId];
      if (currentUserFavoritesMap) {
        // console.log('HERERERERE #1');
        this._entitiesPreloadService.addQueue(currentUserFavoritesMap || {});

        this._entitiesPreloadService.entitiesChange.subscribe(results => {
          if (results) {
            // console.log('FAV entitiesChange.subscribe', results);

            Object.keys(currentUserFavoritesMap).forEach(key => {
              this._favorites[this.userId][key] = currentUserFavoritesMap[key].filter(id => {
                return !!results[key] && !!results[key][id];
              }).map(id => {
                return results[key][id];
              });
              currentUserFavoritesMap[key] = this._favorites[this.userId][key].map(v => v.id);
            });
            // console.log('currentUserFavoritesMap', currentUserFavoritesMap);
            // console.log('this._favorites[this.userId]', this._favorites[this.userId]);
            this._mapArrays();
            this._save();
            this.loading = false;
          } else {
            this.loading = false;
          }
        });
      } else {
        this.loading = false;
      }

      // const callsMap = this._getCallsMap();

      // if (Object.keys(callsMap).length > 0) {
      //   forkJoin(callsMap).subscribe((results: { [key: string]: PaginatedResponse<any> }) => {
      //     Object.keys(results).forEach(key => {
      //       this._favorites[this.userId][key] = results[key].items;
      //       this._arrays[this.userId][key] = results[key].items.map(v => v.id);
      //     });
      //     this._mapArrays();
      //     this._save();
      //     this.loading = false;
      //   });
      // } else {
      //   this.loading = false;
      // }
    }
  }
}
