<div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="flex-start center" [class.wide]="wide">
  <!-- EXPANDED -->
  <ng-container *ngIf="!compact">
    <!-- THUMB -->
    <div *ngIf="thumb" class="thumb" ngClass.xs="thumb-xs" [class.pointer]="isClickable">
      <div matRipple [matRippleDisabled]="!isClickable" [openResource]="isClickable && resource"
        class="resource-preview accent-200-bg" backgroundImage backgroundSize="cover" [backgroundImageSrc]="src">
        <!-- ICON -->
        <app-resource-icon *ngIf="icon" class="resource-preview-icon" [resource]="resource"></app-resource-icon>
        <!-- FPS -->
        <span matTooltip="FPS" *ngIf="isVideo && resource.metadata?.fps" class="resource-preview-fps orange-500"
          [ngClass]="{ 'goodrate': resource.metadata.fps >= 60 }"><app-fps
            [value]="resource.metadata.fps"></app-fps></span>

      </div>
    </div>
  </ng-container>

  <!-- COMPACT -->
  <ng-container *ngIf="compact">
    <!-- ICON -->
    <app-resource-icon *ngIf="icon" class="resource-title-icon grey-400-fg mr-4"
      [resource]="resource"></app-resource-icon>
  </ng-container>

  <!-- INFO -->
  <div *ngIf="hasMetadata" fxLayout="column" class="app-resource-content-info ellipsis">
    <!-- TITLE -->
    <div fxLayout="row" fxLayoutAlign="flex-start center">
      <div class="resource-content-index mr-4" *ngIf="hasIndex">{{ index }}</div>
      <!-- ICON -->
      <app-resource-icon *ngIf="hasNameIcon" class="resource-title-icon grey-400-fg mr-4"
        [resource]="resource"></app-resource-icon>

      <ng-container *ngIf="name && title">
        <!-- NAME LINK -->
        <a class="ellipsis cursor-pointer" *ngIf="isLink" href [openResource]="resource">
          <app-name-ext class="title ellipsis" [value]="title"></app-name-ext>
        </a>

        <!-- NAME -->
        <app-name-ext *ngIf="!isLink" class="title ellipsis" [value]="title"></app-name-ext>
      </ng-container>

      <!-- DURATION BIT RATE INLINE FOR VIDEO -->
      <ng-container *ngIf="!details && showInlineTimerForVideos && isVideo">
        <!-- DURATION -->
        <small class="ml-8" fxLayout="row" fxLayoutAlign="flex-start center" [matTooltip]="'Durata' | translate">
          <mat-icon class="app-icon-xs grey-400-fg mr-4">timer</mat-icon>
          <span *ngIf="!durationControl" class="details ellipsis"
            [ngClass]="{ 'orange-400-fg': time !== defaultDuration }">
            <app-duration [time]="time" [options]="{ hideEmpty: true }"></app-duration>
          </span>
        </small>
      </ng-container>

      <ng-container *ngIf="!details && showBitrateForVideos && isVideo">
        <!-- BIT RATE -->
        <small class="ml-8" fxLayout="row" fxLayoutAlign="flex-start center"
          [matTooltip]="resource.transcodedMetadata?.bit_rate ? ('Bit Rate Transcodificato' | translate) : ('Bit Rate Originale' | translate) ">
          <mat-icon class="app-icon-xs grey-400-fg mr-4">fitbit</mat-icon>
          <span class="details ellipsis" *ngIf="resource.transcodedMetadata?.bit_rate">
            {{ resource.transcodedMetadata?.bit_rate | bitrate }}
          </span>
          <span class="details ellipsis" *ngIf="!resource.transcodedMetadata?.bit_rate">
            {{ resource.metadata.bit_rate | bitrate }}
          </span>
        </small>
      </ng-container>

    </div>

    <!-- DETAILS -->
    <div *ngIf="details" class="font-size-12 resource-details" [ngClass]="{ 'pt-4': !compact, 'grey-400-fg': compact }"
      fxLayout="column">
      <!-- RESOURCE -->
      <div fxLayout="row" fxLayoutGap="8px">
        <!-- RESOLUTION -->
        <span *ngIf="width && height" fxLayout="row" fxLayoutAlign="flex-start center" class="ellipsis"
          [matTooltip]="'Risoluzione' | translate">
          <mat-icon class="grey-400-fg mr-4 app-icon-xs">aspect_ratio</mat-icon>
          <app-resolution class="details ellipsis" [width]="width" [height]="height"></app-resolution>
        </span>
        <!-- DURATION -->
        <span fxLayout="row" fxLayoutAlign="flex-start center" class="ellipsis">
          <div *ngIf="editDuration" class="mr-4" [matTooltip]="'Modifica durata' | translate">
            <button class="app-icon-btn-sm" color="accent" mat-icon-button (click)="onEditDurationClick($event)">
              <mat-icon class="app-icon-xs">edit</mat-icon>
            </button>
          </div>
          <span fxLayout="row" fxLayoutAlign="flex-start center" class="ellipsis" [matTooltip]="'Durata' | translate">
            <mat-icon *ngIf="!editDuration" class="app-icon-xs grey-400-fg mr-4">timer</mat-icon>
            <span class="details ellipsis" [ngClass]="{ 'orange-400-fg': time !== defaultDuration }">
              <app-duration [time]="time" [options]="{ hideEmpty: true }"></app-duration>
            </span>
          </span>
        </span>
        <!-- BIT RATE -->
        <span *ngIf="isVideo" fxLayout="row" fxLayoutAlign="flex-start center" class="ellipsis">
          <mat-icon class="app-icon-xs grey-400-fg mr-4">fitbit</mat-icon>
          <span class="details ellipsis" [matTooltip]="'Bit Rate Transcodificato' | translate"
            *ngIf="resource.transcodedMetadata?.bit_rate">
            {{ resource.transcodedMetadata?.bit_rate | bitrate }}
          </span>
          <span class="details ellipsis" [matTooltip]="'Bit Rate Originale' | translate"
            *ngIf="!resource.transcodedMetadata?.bit_rate">
            {{ resource.metadata.bit_rate | bitrate }}
          </span>
        </span>
      </div>

    </div>
  </div>
</div>