import { Injectable } from '@angular/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { AuthenticationService } from 'app/core/auth/services/authentication.service';
import { CurrentUserService } from 'app/core/auth/services/current-user.service';
import { PaginatedResponse } from 'app/models/pagination.model';
import { SystemInterface } from 'app/models/system.model';
import { HISTORY_MAX } from 'app/modules/global/constants';
import { LS_HISTORY } from 'app/modules/global/localstorage';
import { CircuitRepositoryService } from 'app/repositories/circuit-repository.service';
import { CustomerRepositoryService } from 'app/repositories/customer-repository.service';
import { SystemRepositoryService } from 'app/repositories/system-repository.service';
import { UserRepositoryService } from 'app/repositories/user-repository.service';
import { I18nService } from 'app/services/i18n.service';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, forkJoin, of, zip } from 'rxjs';
import { first } from 'rxjs/operators';
import { EntitiesPreloadService } from './entities-preload.service';

export interface HistoryItem {
  id?: string;
}

export interface HistoryMap {
  [userId: string]: {
    [entityType: string]: HistoryItem[]
  }
}

export interface HistoryLSMap {
  [key: string]: string[],
}

export interface HistoryItemMap {
  [userId: string]: {
    [entityType: string]: string[]
  },
  updatedAt?: any,
}

export interface HistoryMappedMap {
  [userId: string]: {
    [entityType: string]: {
      [favoriteItemId: string]: number
    },
  }
}

export enum HistoryEnum {
  system = 'system',
  systemAnalytics = 'systemAnalytics',
  circuit = 'circuit',
  user = 'user',
  customer = 'customer',
}

@Injectable({
  providedIn: 'root',
})
export class HistoryService {

  protected lsKey = LS_HISTORY;
  protected _arrays: HistoryItemMap = {};
  protected _maps: HistoryMappedMap = {};

  history: HistoryLSMap = {};
  historyChange: BehaviorSubject<any> = new BehaviorSubject([]);

  loading: boolean = false;

  constructor(
    protected _toastrService: ToastrService,
    protected _i18nService: I18nService,
    protected _systemRepositoryService: SystemRepositoryService,
    protected _circuitRepositoryService: CircuitRepositoryService,
    protected _userRepositoryService: UserRepositoryService,
    protected _customerRepositoryService: CustomerRepositoryService,
    protected _authenticationService: AuthenticationService,
    protected _currentUser: CurrentUserService,
    protected _entitiesPreloadService: EntitiesPreloadService,
  ) {
    this._init();
    this._currentUser.onRefresh.subscribe((data) => {
      this._init();
    });
  }

  getHistory(type: HistoryEnum) {
    return !!this.userId && !!this.history && !!this.history[this.userId] && this.history[this.userId][type] || [];
  }

  isFull(type: HistoryEnum) {
    return this.getHistory(type).length >= HISTORY_MAX;
  }

  add(type: HistoryEnum, item: HistoryItem, disabledConfirmMessage?: boolean) {
    if (typeof item === 'object' && item.id) {
      // console.log('isFull', this.isFull(type));

      if (!Array.isArray(this.history[this.userId][type])) {
        this.history[this.userId][type] = [];
      }

      // REMOVE EQUAL ELEMENT
      const foundIndex = this.history[this.userId][type].findIndex(v => v.id === item.id);
      if (foundIndex >= 0) {
        this.history[this.userId][type].splice(foundIndex, 1);
      }

      this.history[this.userId][type].unshift(item);
      this.history[this.userId][type] = this.history[this.userId][type].slice(0, HISTORY_MAX);

      this._save();
      if (!disabledConfirmMessage) {
        // this._toastrService.success('Aggiunto ai recenti');
      }
    }
  }

  remove(type: HistoryEnum, item: HistoryItem, disabledConfirmMessage?: boolean) {
    if (typeof item === 'object' && item.id) {
      if (Array.isArray(this.history[this.userId][type])) {
        const favIndex = this.history[this.userId][type].findIndex(v => v.id === item.id);
        if (favIndex !== -1) {
          // console.log('index to remove', favIndex);
          this.history[this.userId][type].splice(favIndex, 1);
          this._save();
        }
      }
    }
  }

  is(type: HistoryEnum, item: HistoryItem) {
    return !!this._maps[this.userId] && !!this._maps[this.userId][type] && typeof this._maps[this.userId][type][item.id] !== 'undefined';
  }

  protected _mapArrays() {
    this._maps = {};
    const userIds = Object.keys(this._arrays);
    userIds.forEach(userId => {
      this._maps[userId] = {};
      const entityTypes = Object.keys(this._arrays[userId]);
      entityTypes.forEach(entityKey => {
        this._maps[userId][entityKey] = {};
        const ids = this._arrays[userId][entityKey];
        if (Array.isArray(ids)) {
          ids.forEach(id => {
            this._maps[userId][entityKey][id] = 1;
          });
        }
      });
    });
  }

  protected get isValidUser() {
    return this._authenticationService.hasToken() && this._currentUser.hasUser() && this._currentUser.isEnabled();
  }

  get userId() {
    return this.isValidUser ? this._currentUser.getId() : 'guest';
  }

  protected _load() {
    // console.log('::: LOADING :::', this.lsKey, this);

    const currentUserObj1 = {};
    let lsObj = (localStorage.getItem(this.lsKey) ? JSON.parse(localStorage.getItem(this.lsKey)) : {});
    if (!lsObj.updatedAt) {
      currentUserObj1[this.userId] = lsObj;
      this._arrays = currentUserObj1;
    } else {
      currentUserObj1[this.userId] = {};
      this._arrays = Object.assign({}, currentUserObj1, lsObj);
    }

    const currentUserObj2 = {};
    currentUserObj2[this.userId] = {};
    this.history = Object.assign({}, currentUserObj2);
  }

  protected _save(): void {

    Object.keys(this.history).forEach(userId => {
      Object.keys(this.history[userId]).forEach(entityKey => {
        if (!this._arrays[this.userId]) {
          this._arrays[this.userId] = {};
        }
        this._arrays[this.userId][entityKey] = this.history[userId][entityKey].map(v => v.id);
      });
    });

    this._mapArrays();

    this._arrays.updatedAt = new Date().toISOString();

    localStorage.setItem(this.lsKey, JSON.stringify(this._arrays));
    this.historyChange.next(this.history);
  }

  protected _init() {
    // console.log('::: HISTORY INIT :::', this.lsKey, this);
    if (this.isValidUser) {
      this.loading = true;
      this._load();

      const currentUserHistoryMap = this._arrays[this.userId];
      if (currentUserHistoryMap) {
        // console.log('HISTORY HEHEHEHEHEHE  #1');
        this._entitiesPreloadService.addQueue(currentUserHistoryMap || {});

        this._entitiesPreloadService.entitiesChange.subscribe(results => {
          if (results) {
            // console.log('FAV entitiesChange.subscribe', results);

            Object.keys(currentUserHistoryMap).forEach(key => {
              this.history[this.userId][key] = currentUserHistoryMap[key].filter(id => {
                return !!results[key] && !!results[key][id];
              }).map(id => {
                return results[key][id];
              });
              currentUserHistoryMap[key] = this.history[this.userId][key].map(v => v.id);
            });
            // console.log('currentUserHistoryMap', currentUserHistoryMap);
            // console.log('this.history[this.userId]', this.history[this.userId]);
            this._save();
            this.loading = false;
          } else {
            this.loading = false;
          }
        });
      } else {
        this.loading = false;
      }

      // this._fetchIds().subscribe((results: { [key: string]: PaginatedResponse<any> }) => {
      //   Object.keys(results).forEach(key => {
      //     this.history[key] = results[key].items;
      //   });
      //   this.historyChange.next(this.history);
      // });
    }
  }
}
