<div class="dialog-content-wrapper">
  <mat-toolbar matDialogTitle class="mat-accent m-0">
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="flex-start center">
      <div fxFlex>
        <span class="title dialog-title">
          <ng-container *ngIf="!multiple">
            {{ 'Seleziona una risorsa' | translate }}
          </ng-container>
          <ng-container *ngIf="multiple">
            {{ 'Seleziona una o più risorse' | translate }}
          </ng-container>
        </span>
      </div>
      <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>

  <div class="p-0 m-0" fxFlex mat-dialog-content>
    <form class="fr-container" fxLayout="column" fxLayout.gt-sm="row" fxFlex="100" [formGroup]="form"
      name="folderResourcesSelectorForm" novalidate>

      <div fxFlex.gt-sm="1 1 240px" fxLayout="column" [ngClass]="{ 'fluid-disabled': isUploading }"
        class="fr-sidebar grey-200-bg p-12" ngClass.gt-sm="fr-overflow">

        <div *isAdmin class="mb-12">
          <button type="button" mat-button color="accent" (click)="openOldSelector()"><mat-icon>arrow_back</mat-icon>
            {{ 'Torna al vecchio selettore' | translate }}</button>
        </div>

        <!-- CUSTOMER -->
        <div [hidden]="hasOneCustomer" class="mb-12">
          <app-customer-autocomplete [required]="true" [control]="form.get('customer')" [required]="true"
            (changeSelection)="onCustomerChange($event)" [loading]="loadingCustomer"></app-customer-autocomplete>
        </div>

        <!-- FOLDERS -->
        <div [ngClass]="{ 'loading': loadingCustomer }" class="fr-folders mb-12" fxLayout="column" fxFlex
          fxLayoutAlign="flex-start">

          <ng-container *ngIf="!!form.get('customer').value">

            <!-- RECENT FILES -->
            <div matRipple class="border-radius-4 cursor-pointer p-8 mb-12"
              (click)="selectFolder(FolderMenuSectionEnum.recent)" fxLayout="row" fxLayoutAlign="flex-start center"
              [ngClass]="{ 'orange-400': form.get('folder').value === FolderMenuSectionEnum.recent }">
              <mat-icon class="mr-8">update</mat-icon>
              {{'Caricamenti recenti' | translate}}
            </div>

            <!-- FOLDERS SELECTOR -->
            <app-folder-tree-selector fxFlex ngClass.gt-sm="fr-overflow" fxHide.lt-md
              [customerId]="form.get('customer').value?.id" [folderCtrl]="form.get('folder')"
              (folderChange)="onFolderChange($event)"></app-folder-tree-selector>

            <div fxHide.gt-sm class="p-8 border-radius-4" fxLayout="row" fxLayoutAlign="flex-start center"
              [ngClass]="{ 'orange-400': form.get('folder').value && form.get('folder').value !== FolderMenuSectionEnum.recent && form.get('folder').value !== FolderMenuSectionEnum.selected }">
              <mat-icon class="mr-8">folder</mat-icon>
              <app-folder-autocomplete [customerId]="form.get('customer').value?.id" [control]="form.get('folder')"
                (changeSelection)="onFolderChange($event)" floatLabel="never"
                style="margin: -15px 0 -23px;"></app-folder-autocomplete>
            </div>

          </ng-container>

        </div>

        <!-- SELECTED FILES -->
        <div class="fr-selected mb-12">
          <div matRipple class="border-radius-4 cursor-pointer p-8"
            (click)="selectFolder(FolderMenuSectionEnum.selected)" fxLayout="row" fxLayoutAlign="flex-start center"
            [ngClass]="{ 'orange-400': form.get('folder').value === FolderMenuSectionEnum.selected }">
            <mat-icon class="mr-8">checklist</mat-icon>
            <span fxFlex>
              {{'Risorse selezionate' | translate}}
            </span>
            <span class="fr-selected-counter px-8 border-radius-4"
              [ngClass]="{ 'blue': selectedModel.selected.length > 0, 'grey-400-fg grey-50-bg': selectedModel.selected.length === 0 }">
              {{ selectedModel.selected.length }}
            </span>
          </div>
        </div>

      </div>

      <div class="fr-content" fxLayout="column" fxFlex.gt-sm>

        <button *ngIf="!filtersVisible && !areFiltersChanged" mat-button type="button" class="orange-50-bg m-12"
          (click)="showFilters()">
          <mat-icon>filter_list</mat-icon>
          {{ 'Mostra filtri' | translate }}
        </button>

        <!-- TOOLBAR -->
        <div *ngIf="filtersVisible || areFiltersChanged" class="fr-toolbar p-12 pb-0 orange-50-bg m-12 border-radius-4"
          [ngClass]="{ 'fluid-disabled': isUploading }" fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="0"
          fxLayoutGap.gt-sm="12px" fxLayoutAlign.gt-sm="flex-start center" fxLayoutAlign.lt-md="flex-start flex-start">

          <!-- SEARCH BY FILE NAME -->
          <div class="fr-search w-100-p" fxFlex="100" fxFlex.gt-sm="calc(50% - 12px)">
            <mat-form-field fxFlex="100">
              <!-- [ngModel]="searchText" [ngModelOptions]="{ standalone: true }" -->
              <input matInput name="resource_name" formControlName="query"
                [placeholder]="'Cerca per nome file' | translate" autocomplete="off"
                (input)="onResourceSearchChange($event)" />
              <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
          </div>

          <!-- FILTERS -->
          <div class="fr-filters-actions" fxFlex="100" fxFlex.gt-sm="calc(50% - 12px)" fxLayout="row wrap"
            fxLayoutAlign="flex-start center" fxLayoutGap="12px">
            <!-- <button mat-icon-button>
              <mat-icon>filter_list</mat-icon>
            </button> -->

            <div class="mb-12" [ngClass]="{ 'fluid-disabled': selectedResourceTypesDisabled || loadingResources }">
              <mat-button-toggle-group multiple formControlName="types" (change)="onResourceTypeChange($event)">
                <ng-container *ngFor="let resType of resourceTypes">
                  <mat-button-toggle [value]="resType.value" [matTooltip]="resType.label" [aria-label]="resType.label">
                    <mat-icon>{{ resType.icon }}</mat-icon>
                  </mat-button-toggle>
                </ng-container>
              </mat-button-toggle-group>
            </div>

            <div class="xmb-12" fxFlex>
              <mat-form-field>
                <mat-select name="sizes" formControlName="sizes" [placeholder]="'Risoluzione' | translate"
                  (selectionChange)="onResourceSizeChange($event)">
                  <mat-option *ngIf="allowedSizes.length !== 1" [value]="''">{{
                                    'Tutte le risoluzioni' | translate
                                  }}</mat-option>
                  <mat-option *ngFor="let size of allowedSizes" [value]="size">
                    <app-resolution class="ellipsis" [width]="size.width" [height]="size.height"></app-resolution>
                  </mat-option>
                </mat-select>
                <mat-hint *ngIf="allowedSizes.length > 1" class="ellipsis">
                  <span>
                    {{ '{count} {count, plural, one{risoluzione} other{risoluzioni} }' | translate: { count: allowedSizes?.length } }}
                  </span>
                </mat-hint>
              </mat-form-field>
            </div>

            <div class="mb-12">
              <!-- [disabled]="!areFiltersChanged" -->
              <button
                [matTooltip]="areFiltersChanged ? ('Pulisci filtri' | translate) : ('Nascondi filtri' | translate)"
                mat-icon-button (click)="resetFilters($event)" [color]="areFiltersChanged ? 'warn' : ''">
                <mat-icon>filter_list_off</mat-icon>
              </button>
            </div>

          </div>
        </div>
        <!-- FILTERS -->
        <!-- <div class="fr-filter">
          ..filtri
        </div> -->
        <!-- RESULTS -->
        <div class="fr-results-container p-12" fxFlex.gt-sm fxLayout="column">

          <!-- <pre>{{ form.value | json }}</pre> -->


          <!-- TITLE, SORT, UPLOADER TOGGLE -->
          <div [hidden]="!form.get('customer').value && form.get('folder').value !== FolderMenuSectionEnum.selected"
            fxLayout="row" fxLayoutAlign="flex-start center" class="">
            <div fxFlex>
              <ng-container [ngSwitch]="form.get('folder').value">
                <!-- RECENT -->
                <ng-container *ngSwitchCase="FolderMenuSectionEnum.recent">
                  <div class="ml-4 mb-12" fxLayout="row" fxLayoutAlign="flex-start center">
                    <mat-icon class="mr-8">update</mat-icon>
                    <div class="ellipsis h3 mb-4">
                      {{'Caricamenti recenti' | translate}}
                    </div>
                  </div>
                </ng-container>
                <!-- SELECTED FILES -->
                <ng-container *ngSwitchCase="FolderMenuSectionEnum.selected">
                  <div class="ml-4 mb-12" fxLayout="row" fxLayoutAlign="flex-start center">
                    <mat-icon class="mr-8">checklist</mat-icon>
                    <div class="ellipsis h3 mb-4">
                      {{'Risorse selezionate' | translate}}: {{ selectedModel.selected.length }}
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngSwitchDefault>
                  <!-- FOLDER SELECTED -->
                  <ng-container *ngIf="!!form.get('folder').value">
                    <div class="ml-4" fxLayout="row" fxLayoutAlign="flex-start center">
                      <!-- TITLE -->
                      <div class="mb-12 mr-12" fxFlex.lt-md>
                        <div fxLayout="row" fxLayoutAlign="flex-start center">
                          <mat-icon class="mr-8 orange-400-fg">folder</mat-icon>
                          <div>
                            <div class="ellipsis h3 mb-4">
                              {{ form.get('folder').value.name }}
                            </div>
                            <div class="grey-400-fg font-size-12">
                              {{'Ultimo aggiornamento' | translate}}: <app-date [date]="now"
                                formatter="short"></app-date>
                            </div>
                          </div>
                        </div>
                        <!-- Contenuti della cartella <strong>Cartella 1 con nome lunghissimo</strong> -->
                      </div>
                      <!-- UPLOAD BTN -->
                      <div class="mb-12 text-right mr-12">
                        <button *ngIf="allowUpload" [matTooltip]="'Carica altre risorse' | translate" mat-mini-fab
                          [color]="showUploader ? 'primary' : ''" (click)="showUploader = !showUploader"
                          [disabled]="isUploading">
                          <mat-icon>upload</mat-icon>
                        </button>
                      </div>
                    </div>
                  </ng-container>
                  <!-- NO CONTENT -->
                  <div *ngIf="!form.get('folder').value" class="orange-500-fg">
                    {{ 'Seleziona cliente e cartella' | translate }}
                  </div>
                </ng-container>
              </ng-container>
            </div>
            <!-- <div *ngIf="form.get('folder').value !== FolderMenuSectionEnum.selected" class="mb-12">
              <button
                [matTooltip]="searchParams.ascending ? ('Ordine ascendente' | translate) : ('Ordine discendente' | translate)"
                mat-icon-button (click)="onDirectionChange($event)">
                <mat-icon *ngIf="!searchParams.ascending">arrow_downward</mat-icon>
                <mat-icon *ngIf="searchParams.ascending">arrow_upward</mat-icon>
              </button>
            </div> -->
          </div>

          <!-- UPLOADER -->
          <div *ngIf="allowUpload && isUploaderUsable" class="mb-12 p-12 grey-50-bg"
            [hidden]="!form.get('customer').value || !showUploader">

            <ng-container *ngIf="permissions?.canEditUploadContentData">

              <div class="mb-12">
                <app-resource-constraints [(model)]="constraint"></app-resource-constraints>
              </div>

              <app-uploader-images-videos [multiple]="true" [placeholder]="'Carica immagini o video' | translate"
                [showRequirements]="false" [uploadCallback]="uploadCallback" [maxHeight]="160"
                (beforeUploadItem)="uploadStarted($event)" (resourceUploadedAll)="onAllResourcesUploaded()"
                (uploadedAll)="uploadedAll()"></app-uploader-images-videos>
            </ng-container>
          </div>

          <div fxFlex fxLayout="column" [ngClass]="{ 'loading': loadingResources }">

            <div *ngIf="form.get('folder').value !== FolderMenuSectionEnum.selected" fxLayout="row wrap"
              fxLayoutAlign="flex-start center">
              <div class="mr-8 mb-12">
                <span class="app-inputs-label-sm">
                  Totale risorse: {{ resourcesList?.pagination?.totalCount || '0' }}
                </span>
              </div>

              <div fxFlex></div>

              <div class="mb-12 mr-4">
                <button class="app-icon-btn-sm" [matTooltip]="'Aggiorna contenuti' | translate" mat-icon-button
                  (click)="loadResources($event)">
                  <mat-icon>autorenew</mat-icon>
                </button>
              </div>

              <div class="mb-12 mr-8">
                <button class="app-icon-btn-sm"
                  [matTooltip]="searchParams.ascending ? ('Ordine ascendente' | translate) : ('Ordine discendente' | translate)"
                  mat-icon-button (click)="onDirectionChange($event)">
                  <mat-icon *ngIf="!searchParams.ascending">arrow_downward</mat-icon>
                  <mat-icon *ngIf="searchParams.ascending">arrow_upward</mat-icon>
                </button>
              </div>

            </div>

            <div [hidden]="!form.get('customer').value && form.get('folder').value !== FolderMenuSectionEnum.selected"
              fxLayout="column" fxFlex ngClass.gt-sm="fr-overflow">

              <!-- ITEMS -->
              <div class="fr-results" fxLayout="row wrap" fxLayoutAlign="flex-start flex-start">

                <ng-container [ngSwitch]="form.get('folder').value">

                  <ng-container *ngSwitchCase="FolderMenuSectionEnum.selected">
                    <ng-container *ngFor="let resource of selectedModel.selected">
                      <ng-container *ngTemplateOutlet="itemContent; context: { resource: resource } "></ng-container>
                    </ng-container>
                  </ng-container>

                  <ng-container *ngSwitchCase="FolderMenuSectionEnum.recent">
                    <ng-container *ngFor="let resource of resourcesList?.items">
                      <ng-container *ngTemplateOutlet="itemContent; context: { resource: resource } "></ng-container>
                    </ng-container>
                  </ng-container>

                  <ng-container *ngSwitchDefault>
                    <ng-container *ngIf="!!form.get('folder').value">
                      <ng-container *ngFor="let resource of resourcesList?.items">
                        <ng-container *ngTemplateOutlet="itemContent; context: { resource: resource } "></ng-container>
                      </ng-container>
                    </ng-container>
                  </ng-container>

                </ng-container>




              </div>
            </div>

            <!-- PAGINATION -->
            <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutAlign="flex-start flex-start"
              fxLayoutAlign.gt-xs="flex-start center">
              <div class="ml-12">
                <button mat-button type="button" [disabled]="filtersVisible"
                  [ngClass]="{ 'orange-50-bg': !filtersVisible }" (click)="showFilters()">
                  <ng-container *ngIf="form.get('sizes').value">
                    {{'Risoluzione' | translate}}:
                    <app-resolution [width]="form.get('sizes').value.width"
                      [height]="form.get('sizes').value.height"></app-resolution>
                  </ng-container>
                  <ng-container *ngIf="!form.get('sizes').value">
                    <ng-container *ngIf="allowedSizes.length > 0">
                      {{ 'Risoluzioni compatibili' | translate }} ({{ allowedSizes.length }})
                    </ng-container>
                    <ng-container *ngIf="allowedSizes.length === 0">
                      {{ 'Qualsiasi risoluzione' | translate }}
                    </ng-container>
                  </ng-container>
                </button>
              </div>
              <div fxFlex>
                <mat-paginator
                  [hidden]="!form.get('customer').value || form.get('folder').value === FolderMenuSectionEnum.selected"
                  [length]="resourcesList?.pagination.totalCount" [pageSize]="PAGE_SIZE_OPTIONS[0]"
                  [pageSizeOptions]="PAGE_SIZE_OPTIONS">
                </mat-paginator>
              </div>
            </div>
          </div>


          <div class="p-12" fxLayout="row" fxLayoutAlign="flex-start center">
            <div fxFlex></div>
            <button class="mr-12" mat-button (click)="matDialogRef.close()">{{ 'Annulla' | translate }}</button>
            <button color="primary" type="button" mat-raised-button
              [disabled]="selectedModel.selected.length === 0 || isUploading"
              (click)="save()">{{ 'Conferma' | translate }}</button>
          </div>

        </div>

      </div>
    </form>
  </div>

</div>


<ng-template #itemContent let-resource="resource">

  <div class="mb-8 mr-8 fr-result-item border border-radius-4 p-4" [ngClass]="{
                              'blue': selectedModel.isSelected(resource),
                              'fr-result-item-selected': selectedModel.isSelected(resource),
                              'fluid-disabled fluid-checked': !!excludedIdsMap[resource.id]
                            }" matRipple (click)="!excludedIdsMap[resource.id] && selectedModel.toggle(resource)">
    <div class="" fxLayout="row">
      <app-resource-content class="ellipsis" fxFlex="100" [content]="resource" [thumb]="true" [icon]="true"
        [details]="true" [modalPreview]="true"></app-resource-content>
      <div class="m-8">
        <mat-checkbox color="primary" [checked]="selectedModel.isSelected(resource)"
          [disabled]="excludedIdsMap[resource.id]" (click)="onResourceToggle($event, resource)"
          (change)="onResourceCheckToggle($event, resource)"></mat-checkbox>
      </div>
    </div>
    <div fxLayout="row" class="ellipsis fr-date font-size-10" ngClass.xs="fr-date-xs">
      <div fxFlex class="ellipsis">
        <a *ngIf="(form.get('folder').value === FolderMenuSectionEnum.recent || form.get('folder').value === FolderMenuSectionEnum.selected) && resource.folder"
          href="javascript:void(0)" (click)="goToFolder($event, resource)" class="ellipsis">
          /{{ resource.folder?.name }}
        </a>
      </div>
      <app-date [date]="resource.updated_at" formatter="short"></app-date>
    </div>
  </div>
</ng-template>