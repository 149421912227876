import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GroupInterface } from 'app/models/group.model';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { UserRepositoryService } from 'app/repositories/user-repository.service';
import { RoleInterface } from 'app/models/user.model';
import { CurrentUserService } from 'app/core/auth/services/current-user.service';
import { GroupsEditService } from 'app/modules/common/groups-edit/groups-edit.service';

@Component({
  selector: 'app-groups-with-permissions-dialog',
  templateUrl: './groups-with-permissions-dialog.component.html',
  styleUrls: ['./groups-with-permissions-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GroupsWithPermissionsDialogComponent implements OnInit {

  loading: boolean;
  parsedGroupList: any[] = [];

  constructor(
    public matDialogRef: MatDialogRef<GroupsWithPermissionsDialogComponent>,
    private _currentUserService: CurrentUserService,
    private _groupsEditService: GroupsEditService,
    @Inject(MAT_DIALOG_DATA) public data: {
      name?: string
      groups: FormControl;
      parentGroupList: GroupInterface[];
    },
  ) { }

  ngOnInit(): void {
    console.log('this.parentGroupList', this.parentGroupList);
    this.loading = true;
    this._groupsEditService.getAllowedGroups(this.parentGroupList).then(allowedGroups => {
      this.parsedGroupList = (JSON.parse(JSON.stringify(allowedGroups)) || []).map(group => {
        return {
          id: group.id,
          name: group.name,
          write: true,
        }
      });
      this.loading = false;
    });

  }

  get isAdmin() {
    return this._currentUserService.isAdmin();
  }

  get groups(): FormControl {
    return this.data && this.data.groups;
  }

  get name(): string {
    return this.data && this.data.name;
  }

  get parentGroupList(): GroupInterface[] {
    return this.data && this.data.parentGroupList || [];
  }

  save() {
    console.log('save', this.groups);
    this.matDialogRef.close(<{ groups: GroupInterface[] }>{
      groups: this.groups.value,
    });
  }
}
