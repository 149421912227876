import { Component, OnInit, Input, OnChanges, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
import { MatSelectChange } from '@angular/material/select';
import { ResourceConstraintEnum, ResourceConstraintList } from './resource-constraints.model';
import { ResourceConstraintsService } from './resource-constraints.service';
import { Resource, ResourceTranscodeRequest, ResourceTranscodeRequestInterface } from 'app/models/resource.model';
import { AppNumericRangeValidator } from 'app/modules/global/validators/validators';

/**
 *
 *
 * @export
 * @class ResourceConstrainsComponent
 * @description Show resource type constraints
 * @implements {OnChanges}
 */
@Component({
  selector: 'app-resource-constraints',
  templateUrl: './resource-constraints.component.html',
  styleUrls: ['./resource-constraints.component.scss'],
})
export class ResourceConstraintsComponent implements OnInit, OnChanges {
  @Input() model: ResourceTranscodeRequestInterface;
  @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();

  ResourceConstraintList = ResourceConstraintList;
  ResourceConstraintEnum = ResourceConstraintEnum;

  form: FormGroup;

  get codecsList() {
    return this._resourceConstraintsService.getFromExt(
      this.form.get('constraints').get('container').value
    )?.codec || [];
  }

  get container() {
    return this.form.get('constraints').get('container').value;
  }

  constructor(
    private _resourceConstraintsService: ResourceConstraintsService,
  ) { }

  ngOnInit() {
    this.buildForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.model.firstChange) {
      this.buildForm();
    }
  }

  buildForm() {
    const dd = new ResourceTranscodeRequest(this.model);

    let selectedCodec: any = this._resourceConstraintsService.getFromExt(
      dd.constraints.container
    )?.codec.find(v => JSON.stringify(v) === JSON.stringify(dd.constraints.codec));

    if (!selectedCodec) {
      selectedCodec = this._resourceConstraintsService.getFromExt(
        dd.constraints.container
      )?.codec;
    }

    this.form = new FormGroup({
      constraints: new FormGroup({
        container: new FormControl(dd.constraints.container, [Validators.required]),
        codec: new FormControl(selectedCodec, [Validators.required]),
      }),
      bitRateLimit: new FormControl(dd.bitRateLimit, [AppNumericRangeValidator(500, 40_000)]),
      keepTransparency: new FormControl(dd.keepTransparency),
    });

    // console.log('buildFOrm', dd.constraints.codec, this.codecsList);
  }

  onContainerChange(event: MatRadioChange) {
    // console.log('onContainerChange');
    this.form.get('constraints').get('codec').setValue(this.codecsList[0]);
    this.updateModel();
  }

  onCodecsChange(event: MatSelectChange) {
    // console.log('onCodecsChange');
    this.updateModel();
  }

  onBitRateLimitChange() {
    // console.log('onBitRateLimitChange');
    this.updateModel();
  }

  onKeepTransparencyChange() {
    this.updateModel();
  }

  updateModel() {
    this.modelChange.emit(this.form.value);
  }
}
