import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { GroupInterface } from 'app/models/group.model';
import { GroupRepositoryService } from 'app/repositories/group-repository.service';
import { CurrentUserService } from 'app/core/auth/services/current-user.service';
import { I18nService } from 'app/services/i18n.service';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { GroupsEditService } from '../../groups-edit/groups-edit.service';

/**
 *
 *
 * @export
 * @class GeoComponent
 * @description Input address field with map and geolocatiob
 * @implements {OnInit}
 * @implements {OnChanges}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-group-chips',
  templateUrl: './group-chips.component.html',
  styleUrls: ['./group-chips.component.scss'],
})
export class GroupChipsComponent implements OnInit, OnChanges {
  @Input() control: FormControl;
  @Input() placeholder: string = this._i18nService.translate(_('Assegna gruppi'));
  @Input() required: boolean;
  @Input() disabled: boolean;
  @Input() hint: string;
  @Input() loading: boolean;
  @Output() changeSelection: EventEmitter<GroupInterface> = new EventEmitter<GroupInterface>();

  groupList: any = [];

  constructor(
    private _groupRepositoryService: GroupRepositoryService,
    private _currentUserService: CurrentUserService,
    private _groupsEditService: GroupsEditService,
    private _i18nService: I18nService,
  ) { }

  get isAdmin() {
    return this._currentUserService.isAdmin();
  }

  ngOnInit() {
    this.loadGroups();
  }

  loadingGroups: boolean;
  async loadGroups() {
    this.loadingGroups = true;
    this.groupList = await this._groupsEditService.getAllowedGroups() || [];
    this.loadingGroups = false;
  }

  onGroupChange(event) {
    this.changeSelection.emit(this.control.value);
  }

  ngOnChanges(changes) {
    if (changes.control && changes.control.currentValue) {
      this.changeSelection.emit(this.control.value);
    }
  }
}
