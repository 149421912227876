<div class="dialog-content-wrapper">
  <mat-toolbar matDialogTitle class="mat-accent m-0">
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title dialog-title">{{ 'Imposta gruppi con i permessi' | translate }}</span>
      <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>

  <div class="p-12 m-0" ngClass.gt-xs="p-24" mat-dialog-content fxLayout="column">
    <div *ngIf="name" class="mb-12">
      {{ 'Gruppi per' | translate }}: <strong>{{ name }}</strong>
    </div>

    <!-- GROUPS & ROLES -->
    <ng-container *ngIf="isAdmin">
      <div fxFlex="100" fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="16px" class="mt-12"
        [class.loading]="loading">

        <!-- GROUP ACTIONS (READ WRITE SELECTOR) -->
        <ng-template #actionsTpl let-item="item" let-index="index" let-component="component">
          <mat-select [(ngModel)]="item.write" [ngModelOptions]="{standalone: true}" placeholder="W"
            [matTooltip]="'R: Sola lettura, W: Lettura e scrittura' | translate"
            class="px-4 py-8 text-center w-36 ml-8 font-size-10 border-radius-4" (click)="$event.stopPropagation()"
            [ngClass]="{ 'green-300': !item.write, 'orange-300': !!item.write }">
            <mat-option [value]="true">
              W
            </mat-option>
            <mat-option [value]="false">
              R
            </mat-option>
          </mat-select>
        </ng-template>

        <app-autocomplete-chips fxFlex="100" fxFlex.gt-sm="0 1 calc(50% - 16px)" [formControl]="groups"
          [actionsTpl]="actionsTpl" prop="name" [list]="parsedGroupList"
          [placeholder]="'Visibilità Gruppi' | translate"></app-autocomplete-chips>

      </div>
    </ng-container>


  </div>

  <div mat-dialog-actions class="m-0 p-12" ngClass.gt-xs="p-24" fxLayout="row" fxLayoutAlign="space-between center">
    <button mat-button (click)="matDialogRef.close()">{{ 'Annulla' | translate }}</button>
    <button color="primary" type="button" mat-raised-button (click)="save()">{{ 'Conferma' | translate }}</button>
  </div>
</div>